import {NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopNavComponent } from './top-nav/top-nav.component';
import { BottomNavComponent } from './bottom-nav/bottom-nav.component';
import {MatToolbarModule, MatExpansionModule, MatCardModule, MatIconModule, MatButtonModule} from '@angular/material';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';


@NgModule({
  declarations :[TopNavComponent, BottomNavComponent],
  imports: [CommonModule,MatToolbarModule, BrowserAnimationsModule, MatCardModule, MatIconModule, MatButtonModule],
  exports: [ TopNavComponent, BottomNavComponent, MatExpansionModule, BrowserAnimationsModule, MatCardModule, MatIconModule, MatButtonModule],


})
export class CommonComponentsModule {}
