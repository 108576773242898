import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonComponentsModule } from './common-components/common-components.module';
import { AppsComponent } from './portfolio/apps/apps.component';
import { AboutComponent } from './portfolio/about/about.component';



@NgModule({
  declarations: [
    AppComponent,
    AppsComponent,
    AboutComponent,

  ],
  imports: [
    BrowserModule,
    CommonComponentsModule,
    AppRoutingModule,

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
